import Vue from "vue";

/*TITLE*/
document.title = "Edificio España | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio España";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Edificio España";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "4_bremen-roble-193x120--20230112050133.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "3_vela-grey-316x90-stark-black-nature-120x250-bremen-ash--20230112050117.jpg";
Vue.prototype.$image_bath2 = "3_vela-grey-316x90-stark-black-nature-120x250-bremen-ash--20230112050139.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "4_bremen-roble-193x120--20230112050133.jpg",
  },
  {
    src: "3_bremen-ash-193x120--20230112050142.jpg",
  },
  {
    src: "2_bremen-nut-193x120--20230112050150.jpg",
  },
  {
    src: "1_bremen-natural-193x120--20230112050156.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "3_vela-grey-316x90-stark-black-nature-120x250-bremen-ash--20230112050117.jpg",
  },
  {
    src: "2_vela-natural-316x90-stark-black-nature-120x250-bremen-nut--20230112050124.jpg",
  },
  {
    src: "1_vela-smoked-316x90-stark-black-nature-120x250-bremen-natural--20230112050131.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "3_vela-grey-316x90-stark-black-nature-120x250-bremen-ash--20230112050139.jpg",
  },
  {
    src: "2_vela-natural-316x90-stark-black-nature-120x250-bremen-nut--20230112050145.jpg",
  },
  {
    src: "1_vela-smoked-316x90-stark-black-nature-120x250-bremen-natural--20230112050154.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=fuengirola%20málaga%2029640",
    text: "Fuengirola, Málaga, 29640",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20blanca%20paloma%203%20edificio%20atlántico%20local%202%2029640%20fuengirola%20málaga",
    text: "C/ Blanca Paloma, 3. Edificio Atlántico Local 2. 29640 Fuengirola. Málaga",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:952585906",
    text: "952585906",
  },
  {
    icon: "mdi-email",
    link: "mailto:promogesa@promogesa.es",
    text: "promogesa@promogesa.es",
  },
];
